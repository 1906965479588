import React from "react";
import "./Header.css";
import ME from "../../Assets/Fadma.png";
import HeaderSocials from "./HeaderSocials";
const Header = () => {
  return (
    <section>
      <div className="container header_container">
        <div className="me">
          <img src={ME} alt="Fadma El Abdellaoui " />
        </div>
        <div className="hello_card card">
          <div className="icon_card">👋</div>
          <div>
            <div style={{ fontWeight: "400" }}>Hi I'm</div>
            <div className="name-text">Fadma</div>
          </div>
        </div>
        <div className="bottom_header">
          <HeaderSocials />
          <div>
            <h1>
              Web <span className="name-fdm">Developer</span> & Digital{" "}
              <span className="name-fdm">Marketer</span>
            </h1>
            <div className="about_content">
              I'm highly interested in challenges and passionate about marketing
              and communication.<br></br> I'm recognized for a data-driven
              approach and creative ideas, especially when it comes to helping
              businesses grow their audience and brand awareness.
            </div>
          </div>

          <div className="scroll_container">
            <a href="#contact" className="scroll_down">
              Scroll Down
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
