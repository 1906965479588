import React from "react";
import "./Projects.css";
import sep from "../../Assets/separator.webp";
import mailchimp from "../../Assets/skills/mailchimp.png";
import sm from "../../Assets/skills/SM.png";
import whatsappB from "../../Assets/skills/WhatsAppB.png";
import weatherApp from "../../Assets/weather-app-screen.png";
import memorygame from "../../Assets/memory-game-screen.png";
import dictionary from "../../Assets/dictionary-screen.png";
const Projects = () => {
  return (
    <section id="projects">
      <div className="container">
        <img src={sep} alt="separator element" className="sep" />
        <h1>
          My <span className="name-fdm">skills</span> & recent
          <span className="name-fdm"> work</span>
        </h1>
        <div className="skills">
          <div className="skill">HTML</div>
          <div className="skill">CSS</div>
          <div className="skill">Javascript</div>
          <div className="skill">Bootstrap</div>
          <div className="skill">SQL</div>
          <div className="skill">Java</div>
          <div className="skill">React Js</div>
          <div className="skill">React Native</div>
          <div className="skill">Sana commerce</div>
          <div className="skill">Google Analytics</div>
          <div className="skill">Mailchimp</div>
        </div>
        <div className="container portfolio_container">
          <article className="portfolio_item">
            <div className="portfolio_item-image">
              <img src={mailchimp} alt="MailChimp" className="digital" />
            </div>
            <h3>Analyzing a customer database using MailChimp </h3>
            <ul className="project-steps">
              <li>Configuring the account.</li>
              <li>Managing multiple audiences and setting up segments.</li>
              <li>Categorizing clients based on specific criterias.</li>
              <li>Creating customizable campaigns.</li>
              <li>Automating newsletters and other marketing concepts.</li>
            </ul>
          </article>
          <article className="portfolio_item">
            <div className="portfolio_item-image">
              <img
                src={whatsappB}
                alt="WhatsApp Business"
                className="digital"
              />
            </div>
            <h3>WhatsApp business</h3>
            <ul className="project-steps">
              <li>Carry-out of WhatsApp Business launch.</li>
              <li>Creating and configuring the account.</li>
              <li>Creating customers groups based on multiple criterias.</li>
              <li>Developing and executing marketing plans.</li>
            </ul>
          </article>
          <article className="portfolio_item">
            <div className="portfolio_item-image">
              <img src={sm} alt="Social media" className="digital" />
            </div>
            <h3>Managing social media accounts</h3>
            <ul className="project-steps">
              <li>Elaborating the editorial calendar.</li>
              <li>Deploying social media campaigns.</li>
              <li>Creating content for social networks.</li>
              <li>Animating and moderating social networks.</li>
              <li>Elaborating benchmarks and competitive intelligence.</li>
            </ul>
          </article>
          <article className="portfolio_item">
            <a
              href="https://weather-app-fdm.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="portfolio_item-image">
                <img src={weatherApp} alt="Weather App" />
              </div>
              <h3>This is a Weather App build with React JS</h3>
            </a>
          </article>
          <article className="portfolio_item">
            <a
              href="https://memory-game-fdm.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="portfolio_item-image">
                <img src={memorygame} alt="Memory game " />
              </div>
              <h3>This is a Memory App build with Javascript</h3>
            </a>
          </article>
          <article className="portfolio_item">
            <a
              href="https://dictionary-app-fdm.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="portfolio_item-image">
                <img src={dictionary} alt="English Dictionary " />
              </div>
              <h3>This is an English Dictionary build with Javascript </h3>
            </a>
          </article>
        </div>
        <a href="#contact" className="btn btn-primary">
          Let's work together !
        </a>
      </div>
    </section>
  );
};

export default Projects;
