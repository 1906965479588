import React from "react";
import "./Contact.css";
import sep from "../../Assets/separator.webp";

import { useRef } from "react";
import emailjs from "emailjs-com";
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_3ku84td",
      "template_aora51z",
      form.current,
      "YzSunlRLrQePjyPY1"
    );
    e.target.reset();
  };
  return (
    <section id="contact">
      <div className="container">
        <img src={sep} alt="separator element" className="sep" />
        <h1>
          Contact <span className="name-fdm">Me</span>
        </h1>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className=" btn btn-primary">
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};
export default Contact;
