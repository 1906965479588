import Header from "./Components/Header/Header";
import "./App.css";
import Nav from "./Components/Nav/Nav";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";

function App() {
  return (
    <div className="App">
      <div className="banner"></div>
      <Header />
      <Nav />
      <Projects />
      <Contact />
      <div className="banner"></div>
    </div>
  );
}

export default App;
