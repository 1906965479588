import React from "react";
import "./Nav.css";
import { BiMessageSquareDetail } from "react-icons/bi";
import { IoHomeOutline } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import { BsJournalBookmark } from "react-icons/bs";
import { useState } from "react";
const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <IoHomeOutline style={{ fontSize: "25px" }} />
      </a>
      <a
        href="#projects"
        onClick={() => setActiveNav("#projects")}
        className={activeNav === "#projects" ? "active" : ""}
      >
        <BsJournalBookmark style={{ fontSize: "25px" }} />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail style={{ fontSize: "25px" }} />
      </a>
      <a
        href="https://www.linkedin.com/in/fadmaelabdellaoui/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <FaLinkedinIn style={{ fontSize: "25px" }} />
      </a>
    </nav>
  );
};
export default Nav;
