import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
const HeaderSocials = () => {
  return (
    <div className="header_socials">
      <a
        href="https://www.linkedin.com/in/fadmaelabdellaoui/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedinIn style={{ fontSize: "29px" }} />
      </a>
    </div>
  );
};

export default HeaderSocials;
